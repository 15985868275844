<template>
  <div id="documentation-content">
    <div class="cover" :style="'background-image: url(' + selected_service.cover + ');'"></div>
    <Navigation/>
    <div class="doc-services-container">
      <div class="square animate__animated animate__fadeInLeft"></div>
      <div class="services-list">
        <ul class="service-links animate__animated animate__fadeInDown">
          <li v-for="service in services" v-bind:key="service.id" v-bind:class="service.id == selected_service.id ? 'selected':''">
            <a :href="'/documentation/'+service.url" @mouseover="previewService(service.id)">{{service.title}}</a>
          </li>
        </ul>
      </div>
      <div class="service-content">
        <div class="service animate__animated animate__fadeInDown">
          <div class="service-title">{{selected_service.title}}</div>
          <div class="service-body" v-html="selected_service.content"></div>
        </div>
        <div class="service-images">
          <img v-for="image of selected_service.images" v-bind:key="image" v-bind:src="image" class="service-img animate__animated animate__fadeInDown" alt="">
        </div>
      </div>
    </div>
    <More @next="pageMove"/>
    <MobileMore/>
  </div>
</template>

<script>
import store from '@/store'
export default {
  props: {
    url: String
  },
  components: {
    Navigation: () => import('@/components/nav'),
    More: () => import('@/components/more'),
    MobileMore: () => import('@/components/mobile-more')
  },
  computed: {
    services: () => store.state.documentation.services
  },
  data: () => ({
    selected_service: {}
  }),
  mounted() {
    store.commit('SET_NAV_PAGE', 'documentation')
    store.commit('SET_MORE_PAGES', this.services)
    this.previewService(this.url)
    store.commit('SET_MORE_HAS_SCROLL', true)
  },
  methods: {
    previewService: function (url) {
      for (let service of this.services) {
        if (service.url == url) {
          this.selected_service = service;
          document.getElementById('documentation').style.backgroundImage = `url(${service.cover})`;
        }
      }
    },
    pageMove: function (index) {
      var ctr = 0
      for (let service of this.services) {
        if (ctr == index) {
          history.pushState(
            {},
            null,
            '/documentation/' + service.url
          )
          this.selected_service = service
        }
        ctr++
      }
    }
  }
}
</script>

<style>
body {
  background-color: #f1f1f1;
}
#documentation {
  background-position: 130%;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
<style scoped>
div#documentation-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.doc-services-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 1600px;
  height: calc(100% - 200px);
  background: white;
  display: flex;
}
.square {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #008ED6;
  width: 195px;
  height: 195px;
}

.services-list {
  border-right: 1px solid rgba(13,13,13,.5);
  width: 350px;
}
ul.service-links {
  margin-top: 200px;
  list-style-type: none;
  padding-left: 150px;
}
ul.service-links li {
  margin-right: -3px;
}
ul.service-links li a {
  color: #CFCFCF;
  padding: 6px 12px;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: bold;
  line-height: 53px;
  text-decoration: none;
  position: relative;
}
ul.service-links li.selected {
  border-right: 5px solid #008ED6;
}
ul.service-links li.selected a {
  color: #008ED6;
}

.service-content {
  display: flex;
  justify-content: space-around;
  width: calc(100% - 350px);
}

.service {
  margin-top: 130px;
  padding-left: 100px;
  width: 500px;
}
.service-title {
  font-family: "Nanum Gothic";
  font-size: 35px;
  font-weight: bold;
  line-height: 40px;
  padding: 0 0 24px;
  color: #008ED6;
}

.service-images {
  width: 389px;
  margin-top: calc(130px - 80px);
  display: flex;
  flex-direction: column;
}
.service-images img {
  margin-top: 24px;
  margin-left: 24px;
  width: calc(100% - 24px);
}

div.cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: calc(1600px - 10%);
  margin-left: auto;
  width: 100%;
  height: 100%;
}

@media(max-width: 1280px) {
  ul.service-links {
    margin-top: 120px;
    padding-left: 100px;
  }
  .service-content {
    padding: 24px;
  }
  .service {
    margin-top: 80px;
    padding-left: 0;
  }
  .service-images {
    margin-top: 32px;
  }
}
@media(max-width: 1024px) {
  ul.service-links {
    margin-top: 64px;
    padding-left: 64px;
  }
  ul.service-links li a {
    line-height: 36px;
  }
  .service-content {
    padding: 24px;
  }
  .service {
    margin-top: 0;
    padding-left: 0;
  }
  .service-images {
    margin-top: 0;
  }
}

@media(max-width: 768px) {
  .square {
    display: none;
  }
  .doc-services-container {
    height: calc(100% - 64px - 66px - 64px);
    bottom: 64px;
    flex-direction: column;
  }
  .services-list {
    width: 100%;
  }
  ul.service-links {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 12px;

  }
  ul.service-links li {
    flex: 0 0 calc(50% - 16px);
    border: 1px solid #CFCFCF;
    margin: 6px;
  }
  ul.service-links li.selected {
    border: 2px solid #008ED6;
  }
  ul.service-links li a {
    display: block;
  }
  .service {
    width: 100%;
  }
  .service-content {
    width: calc(100% - 48px);
    padding-top: 0;
  }
  .service-images {
    margin-top: -24px;
  }
  .service-title {
    font-size: 24px;
  }
  .service-body {
    font-size: 16px;
  }
}

@media(max-width: 576px) {
  .service-content {
    flex-direction: column;
  }
  .service-title {
    font-size: 16px;
  }
  .service-body {
    font-size: 12px;
  }
  .service-images {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 24px 0 0;
  }
  .service-images img {
    flex: 0 0 calc(50% - 6px);
    width: calc(50% - 12px);
    margin: 0;
  }
  ul.service-links li a {
    font-size: 12px;
    line-height: 24px;
  }
}

@media(max-height: 680px) {
  .doc-services-container {
    height: calc(100% - 64px - 100px);
    overflow: hidden;
  }
  ul.service-links {
    padding: 6px 12px;
  }
  .service-content {
    position: absolute;
    top: 114px;
    bottom: 0;
    overflow-y: scroll;
    display: block;
  }
  .service-title {
    padding: 0;
  }
}
</style>

<style>

@media(max-width: 1024px) {
  #plusfriend-chat-button {
    bottom: 24px;
  }
}

@media(max-width: 768px) {
  #plusfriend-chat-button {
    bottom: 64px;
  }
}
</style>
